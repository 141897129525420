import { TEndpoint } from "@@types/api.core";
import Api from "@core/api.core";
import { AxiosRequestConfig } from "axios";
import getConfig from "next/config";
import { mergeRight } from "ramda";

const { apiPymesPlans } = getConfig().publicRuntimeConfig;

export class PymesRepository extends Api {
  protected readonly endpoints: TEndpoint = {
    plans: {
      monthlyPlan: "/?productType=plan-mesual",
      annualPlan: "/?productType=plan-anual"
    }
  };

  constructor(baseOptions: AxiosRequestConfig = {}) {
    const defaultHeaders = {
      headers: 'accept-language: "es-CO"'
    };

    super(mergeRight({ baseURL: apiPymesPlans, headers: defaultHeaders }, baseOptions));
  }

  async getMonthPlan() {
    return this.get(this.endpoints.plans.monthlyPlan).then((response) => response.data);
  }

  async getAnnualPlan() {
    return this.get(this.endpoints.plans.annualPlan).then((response) => response.data);
  }
}

export default new PymesRepository();
